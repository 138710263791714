.Leagues__Wrapper {
  margin-bottom: 50px;
}

.Leagues__Card {
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 15px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 0.25s;
  overflow: hidden;
}

.Leagues__Card:hover {
  transform: scale(1.02);
  transition: ease 0.25s;
}

.Leagues__Icon {
  /* padding-top: 40px; */
  color: #0099ff;
}

.Leagues__CreateLeagueCard {
  color: white !important;
  text-decoration: none;
}

.search {
  width: 50%;
  /* position: relative; */
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 2px solid #0099ff;
  border-right: none;
  padding: 5px;
  height: 24px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: black;
}

.searchButton {
  width: 80px;
  height: 38px;
  border: 2px solid #0099ff;
  background: #0099ff;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.searchButton:hover {
  background: white;

  color: #0099ff;
}
