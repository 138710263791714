.HomeInstructions__Section {
  margin-top: 100px;
  padding: 25px 25px 100px 25px;
  background-color: rgb(238, 238, 238);
  color: black;
}

.HomeInstructions__Icon {
  width: 150px !important;
  height: 150px !important;
  padding: 20px;
  color: #0099ff;
  /* transform: scale(5); */
}

.HomeInstructions__Card {
  padding: 20px;
}
