html,
body {
  scroll-behavior: smooth;
}

.homeJumbotron__main {
  position: relative;
  display: block;
  /* width: 100%; */
  height: 100vh;
  margin-top: -74px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  backdrop-filter: brightness(0.4);
  background-image: url("https://firebasestorage.googleapis.com/v0/b/derby-584f8.appspot.com/o/assets%2Fderby_homeBKG2%20(1).jpg?alt=media&token=230c3b84-2d50-42b8-b69f-6877e520784e");
}

.homeJumbotron__content {
  position: absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.homeJumbotron__title {
  font-size: 7rem;
  color: white;
  margin-top: 250px;
  margin-bottom: 20px;
}

.homeJumbotron__subtitle {
  margin: 0px;
  font-size: 2rem;
  color: white;
}

/* .homeJumbotron__logo {
  max-height: 150px;
} */

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 600px) {
  .homeJumbotron__title {
    font-size: 3rem;
  }
  .homeJumbotron__subtitle {
    font-size: 1rem;
  }
}
