.leagueDetails__wrapper {
  background-color: #eee;
}

.leagueDetails__header {
  align-items: center;
  text-align: center;
  background: rgb(102, 159, 255);
  background: linear-gradient(
    350deg,
    rgba(102, 159, 255, 1) 0%,
    rgba(131, 94, 255, 1) 28%,
    rgba(232, 94, 255, 1) 100%
  );
  margin-top: -74px;
  padding: 100px 10px 50px 10px;
  /* margin-bottom: 20px; */
  color: white;
}

.leagueDetails__rosterIMG {
  width: 100px;
  border-radius: 20%;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.leagueDetails__rosterIMG2 {
  width: 100px;
  border-radius: 20%;
}

.correctGuess {
  border: 5px solid green;
}

.incorrectGuess {
  border: 5px solid red;
}

.episodePagination {
  /* margin: auto; */
  /* width: 90%; */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.episodePaginationNoResultBox {
  /* width: 90%; */
  background-color: #0099ff;
  color: white;
  padding: 20px;
  /* height: 400px; */
  border-radius: 10px;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.25s;
}

.episodePaginationNoResultBox:hover {
  background-color: #002984;
  transition: ease 0.25s;
}

.leagueDetails__leaderboard {
  background-color: white;
  width: 90%;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 30px;
}

.leagueDetails__dashboard {
  padding: 30px;
}

.pointsBadge {
  background-color: #0099ff;
  color: white;
  width: 50px;
  padding: 8px;
  border-radius: 5px;
  font-weight: 800;
  text-align: center;
}

.episodePicks__container {
  /* border: 1px solid black; */
  width: 100%;
}

.episodePicks__pointRow {
  /* border: 1px solid black; */
  width: 100%;
  padding: 10px 10px;
}

hr {
  background-color: #ccc;
  height: 1px;
  border: none;
}

.episodePicks__textEmphasis {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0;
}

.episodePicks__pointCategoryLabel {
  margin: 0px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .leagueDetails__dashboard {
    padding: 0px;
  }
  /* .leagueDetails__rosterIMG {
    height: 100px;
    width: 100px;
  }
  .leagueDetails__rosterIMG2 {
    height: 100px;
    width: 100px;
  } */
  .episodePicks__pointCategoryLabel {
    font-size: 1rem;
  }
}
