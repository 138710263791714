.jumbotron__main {
  position: relative;
  /* background-image: url("../../images/jumbo_bkg.jpg"); */
  /* height: 50vh; */
  margin-top: -74px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: #454545; */
  background: rgb(102, 159, 255);
  background: linear-gradient(
    350deg,
    rgba(102, 159, 255, 1) 0%,
    rgba(131, 94, 255, 1) 28%,
    rgba(232, 94, 255, 1) 100%
  );
}

.jumbotron__content {
  position: absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  text-align: center;
  /* top: 40%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.jumbotron__title {
  font-size: 5rem;
  color: white;
  margin-top: 150px;
}

.jumbotron__subtitle {
  margin: 0px;
  font-size: 2rem;
  color: white;
}

.jumbotron__logo {
  max-height: 150px;
}
