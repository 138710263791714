@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.HeaderText__h2 {
  font-size: 64px;
  font-weight: 800;
  font-family: "Segoe UI", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 20px;
}



/* ========================================
FOOTER STYLING
======================================== */

footer {
  /* height: 24vh; */
  width: 100%;
  bottom: 0;
  /* color: white; */
  background-color: #454545;
  text-align: center;
}

.Footer__Container {
  padding: 30px;
}

.Footer__Logo {
  width: 200px;
}
.Footer__Icon {
  color: white;
}

.Footer__Icon:hover {
  color: #0099ff;
}

/* .footer__ul {
  float: center;
}
.footer__li {
  float: center;
  display: block;
}

.footer__liWrapper {
  width: 100%;
} */

/* .footer-accent {
  background: -webkit-linear-gradient(
    339deg,
    rgba(255, 74, 118, 1) 0%,
    rgba(235, 126, 62, 1) 50%,
    rgba(245, 51, 78, 1) 100%
  );
  height: 1vh;
  margin-bottom: 1vh;
} */

.jumbotron__main {
  position: relative;
  /* background-image: url("../../images/jumbo_bkg.jpg"); */
  /* height: 50vh; */
  margin-top: -74px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: #454545; */
  background: rgb(102, 159, 255);
  background: linear-gradient(
    350deg,
    rgba(102, 159, 255, 1) 0%,
    rgba(131, 94, 255, 1) 28%,
    rgba(232, 94, 255, 1) 100%
  );
}

.jumbotron__content {
  position: absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  text-align: center;
  /* top: 40%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.jumbotron__title {
  font-size: 5rem;
  color: white;
  margin-top: 150px;
}

.jumbotron__subtitle {
  margin: 0px;
  font-size: 2rem;
  color: white;
}

.jumbotron__logo {
  max-height: 150px;
}

.Leagues__Wrapper {
  margin-bottom: 50px;
}

.Leagues__Card {
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 0.25s;
  overflow: hidden;
}

.Leagues__Card:hover {
  transform: scale(1.02);
  transition: ease 0.25s;
}

.Leagues__Icon {
  /* padding-top: 40px; */
  color: #0099ff;
}

.Leagues__CreateLeagueCard {
  color: white !important;
  text-decoration: none;
}

.search {
  width: 50%;
  /* position: relative; */
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 2px solid #0099ff;
  border-right: none;
  padding: 5px;
  height: 24px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: black;
}

.searchButton {
  width: 80px;
  height: 38px;
  border: 2px solid #0099ff;
  background: #0099ff;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.searchButton:hover {
  background: white;

  color: #0099ff;
}


html,
body {
  scroll-behavior: smooth;
}

.homeJumbotron__main {
  position: relative;
  display: block;
  /* width: 100%; */
  height: 100vh;
  margin-top: -74px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-backdrop-filter: brightness(0.4);
          backdrop-filter: brightness(0.4);
  background-image: url("https://firebasestorage.googleapis.com/v0/b/derby-584f8.appspot.com/o/assets%2Fderby_homeBKG2%20(1).jpg?alt=media&token=230c3b84-2d50-42b8-b69f-6877e520784e");
}

.homeJumbotron__content {
  position: absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.homeJumbotron__title {
  font-size: 7rem;
  color: white;
  margin-top: 250px;
  margin-bottom: 20px;
}

.homeJumbotron__subtitle {
  margin: 0px;
  font-size: 2rem;
  color: white;
}

/* .homeJumbotron__logo {
  max-height: 150px;
} */

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 600px) {
  .homeJumbotron__title {
    font-size: 3rem;
  }
  .homeJumbotron__subtitle {
    font-size: 1rem;
  }
}

.Card {
  height: 500px;
  width: 90%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: block;
  padding: 0;
  transition: ease 0.25s;
  margin: 10px;
}

.Card:hover {
  transform: scale(1.03);
  transition: ease 0.25s;
}

.Card__Img {
  height: 350px;
  width: 100%;
  object-fit: cover;
  display: block;
}

.Card__Content {
  padding: 15px;
}

.HomeInstructions__Section {
  margin-top: 100px;
  padding: 25px 25px 100px 25px;
  background-color: rgb(238, 238, 238);
  color: black;
}

.HomeInstructions__Icon {
  width: 150px !important;
  height: 150px !important;
  padding: 20px;
  color: #0099ff;
  /* transform: scale(5); */
}

.HomeInstructions__Card {
  padding: 20px;
}

.CreateLeague__Wrapper {
  margin-bottom: 50px;
}

.JoinLeague__Wrapper {
  margin-bottom: 50px;
  /* height: 80vh; */
}

/* ========================================
NAV STYLING
======================================== */

/* .nav { */
/* width: 100%;
  height: 54px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  background-color: #0099ff;
  opacity: 0;
  position: absolute; */
/* } */

.nav {
  /* width: 100%; */
  height: 54px;
  margin-top: 20px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  /* opacity: 0;
  position: absolute; */
}

.nav-hamburger {
  display: none;
}

.nav-logo {
  padding-left: 10px;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  /* padding: 10px; */
  font-weight: bold;
  width: 150px;
  z-index: 2;
}

.nav-logo:hover {
  text-decoration: none;
  color: #f15bb5;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  z-index: 2;
}

li {
  float: right;
  margin-right: 20px;
  z-index: 2;
}

li a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  z-index: 2;
  border-bottom: 2px solid transparent;
  transition: ease 0.5s;
}

li a:hover {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  border-bottom: 2px solid white;
  transition: ease 0.5s;
}

.active {
  display: block;
}

.MuiPaper-root {
  /* background-color: #424242 !important; */
}

.drawer__list {
  display: flex;
  flex-direction: column;
}

.drawer__item {
  padding: 15px;
  width: 200px;
  color: #0099ff;
  font-weight: 500;
  float: left;
  /* border: 1px gray solid; */
}

.drawer__list > a {
  text-decoration: none;
}
.drawer__list > a:hover {
  text-decoration: none;
  font: black !important;
}

.drawer__item:hover {
  background-color: gray;
}

.nav__hamburger {
  display: none;
  z-index: 3;
  padding: 10px 20px;
}

.nav-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  z-index: 2;
}

.button__logout {
  border: 2px solid #0099ff;
  background-color: #0099ff;
  padding: 10px 30px;
  border-radius: 30px;
  transition: ease 0.2s;
}

.button__logout:hover {
  border: 2px solid white;
  background-color: white;
  color: #0099ff;
  transition: ease 0.2s;
}

/* MEDIA QUERIES */

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  .nav-item {
    display: none;
  }
  .nav__hamburger {
    float: right;
    display: block;
  }
}

.leagueDetails__wrapper {
  background-color: #eee;
}

.leagueDetails__header {
  align-items: center;
  text-align: center;
  background: rgb(102, 159, 255);
  background: linear-gradient(
    350deg,
    rgba(102, 159, 255, 1) 0%,
    rgba(131, 94, 255, 1) 28%,
    rgba(232, 94, 255, 1) 100%
  );
  margin-top: -74px;
  padding: 100px 10px 50px 10px;
  /* margin-bottom: 20px; */
  color: white;
}

.leagueDetails__rosterIMG {
  width: 100px;
  border-radius: 20%;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.leagueDetails__rosterIMG2 {
  width: 100px;
  border-radius: 20%;
}

.correctGuess {
  border: 5px solid green;
}

.incorrectGuess {
  border: 5px solid red;
}

.episodePagination {
  /* margin: auto; */
  /* width: 90%; */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.episodePaginationNoResultBox {
  /* width: 90%; */
  background-color: #0099ff;
  color: white;
  padding: 20px;
  /* height: 400px; */
  border-radius: 10px;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.25s;
}

.episodePaginationNoResultBox:hover {
  background-color: #002984;
  transition: ease 0.25s;
}

.leagueDetails__leaderboard {
  background-color: white;
  width: 90%;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 30px;
}

.leagueDetails__dashboard {
  padding: 30px;
}

.pointsBadge {
  background-color: #0099ff;
  color: white;
  width: 50px;
  padding: 8px;
  border-radius: 5px;
  font-weight: 800;
  text-align: center;
}

.episodePicks__container {
  /* border: 1px solid black; */
  width: 100%;
}

.episodePicks__pointRow {
  /* border: 1px solid black; */
  width: 100%;
  padding: 10px 10px;
}

hr {
  background-color: #ccc;
  height: 1px;
  border: none;
}

.episodePicks__textEmphasis {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0;
}

.episodePicks__pointCategoryLabel {
  margin: 0px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .leagueDetails__dashboard {
    padding: 0px;
  }
  /* .leagueDetails__rosterIMG {
    height: 100px;
    width: 100px;
  }
  .leagueDetails__rosterIMG2 {
    height: 100px;
    width: 100px;
  } */
  .episodePicks__pointCategoryLabel {
    font-size: 1rem;
  }
}

.SelectSeasonRoster__selectImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.SelectSeasonRoster__selectName {
  font-size: 2rem;
}

.SelectSeasonRoster__MenuItem {
  display: block;
  /* background-color: blue; */
  /* width: 300px; */
  float: left;
}

.SelectSeasonRoster__Select {
  display: inline;
  /* background-color: red; */
}

.MuiPaper-root {
  /* background-color: white !important; */
}

.QueenSelect__Card {
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.SelectEpisodeRoster__selectImg {
  padding-right: 20px;
}

.QueenSelect__PointValue {
  background-color: #0099ff;
  padding: 5px;
  border-radius: 10px;
  color: white;
  font-weight: 600 !important;
}

.QueenSelect__Eliminated {
  -webkit-filter: grayscale();
          filter: grayscale();
  /* background-color: lightgray !important; */
  text-decoration: line-through;
}

.SelectEpisodeRoster__selectImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.SelectEpisodeRoster__selectName {
  font-size: 1rem;
}

.SelectEpisodeRoster__MenuItem {
  display: block;
  /* background-color: blue; */
  /* width: 300px; */
  float: left;
}

.SelectEpisodeRoster__Select {
  display: inline;
  /* background-color: red; */
}

