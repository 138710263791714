/* ========================================
FOOTER STYLING
======================================== */

footer {
  /* height: 24vh; */
  width: 100%;
  bottom: 0;
  /* color: white; */
  background-color: #454545;
  text-align: center;
}

.Footer__Container {
  padding: 30px;
}

.Footer__Logo {
  width: 200px;
}
.Footer__Icon {
  color: white;
}

.Footer__Icon:hover {
  color: #0099ff;
}

/* .footer__ul {
  float: center;
}
.footer__li {
  float: center;
  display: block;
}

.footer__liWrapper {
  width: 100%;
} */

/* .footer-accent {
  background: -webkit-linear-gradient(
    339deg,
    rgba(255, 74, 118, 1) 0%,
    rgba(235, 126, 62, 1) 50%,
    rgba(245, 51, 78, 1) 100%
  );
  height: 1vh;
  margin-bottom: 1vh;
} */
