/* ========================================
NAV STYLING
======================================== */

/* .nav { */
/* width: 100%;
  height: 54px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  background-color: #0099ff;
  opacity: 0;
  position: absolute; */
/* } */

.nav {
  /* width: 100%; */
  height: 54px;
  margin-top: 20px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  /* opacity: 0;
  position: absolute; */
}

.nav-hamburger {
  display: none;
}

.nav-logo {
  padding-left: 10px;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  /* padding: 10px; */
  font-weight: bold;
  width: 150px;
  z-index: 2;
}

.nav-logo:hover {
  text-decoration: none;
  color: #f15bb5;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  z-index: 2;
}

li {
  float: right;
  margin-right: 20px;
  z-index: 2;
}

li a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  z-index: 2;
  border-bottom: 2px solid transparent;
  transition: ease 0.5s;
}

li a:hover {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  border-bottom: 2px solid white;
  transition: ease 0.5s;
}

.active {
  display: block;
}

.MuiPaper-root {
  /* background-color: #424242 !important; */
}

.drawer__list {
  display: flex;
  flex-direction: column;
}

.drawer__item {
  padding: 15px;
  width: 200px;
  color: #0099ff;
  font-weight: 500;
  float: left;
  /* border: 1px gray solid; */
}

.drawer__list > a {
  text-decoration: none;
}
.drawer__list > a:hover {
  text-decoration: none;
  font: black !important;
}

.drawer__item:hover {
  background-color: gray;
}

.nav__hamburger {
  display: none;
  z-index: 3;
  padding: 10px 20px;
}

.nav-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  z-index: 2;
}

.button__logout {
  border: 2px solid #0099ff;
  background-color: #0099ff;
  padding: 10px 30px;
  border-radius: 30px;
  transition: ease 0.2s;
}

.button__logout:hover {
  border: 2px solid white;
  background-color: white;
  color: #0099ff;
  transition: ease 0.2s;
}

/* MEDIA QUERIES */

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  .nav-item {
    display: none;
  }
  .nav__hamburger {
    float: right;
    display: block;
  }
}
